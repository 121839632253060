@font-face {
  font-family: "Roboto";
  src: url("/fonts/Roboto-Regular.ttf");
  font-display: swap;
}

/* @font-face {
  font-family: 'Roboto';
  src: url('/fonts/RobotoCondensed-VariableFont_wght.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Mulish-VariableFont_wght.ttf');
  font-display: swap;
} */

/* this should be handled by the baseline css component */
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.87);
  /* -webkit-box-shadow: 0 0 0px 1000px #000 inset; */
  transition: background-color 5000s ease-in-out 0s;
}

code {
  font-family: Roboto;
}

.background-shadow {
  height: 100%;
}

body,
html,
#root {
  margin: 0;
  height: 100%;
  font-family: "Roboto", sans-serif;
}

* {
  box-sizing: border-box;
}
